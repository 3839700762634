import { Injectable } from "@angular/core";
import
  {
    ErrorHandlerCreator, ResponseErrorHandler
  } from "./response-error-handler.service";
import
  {
    APIService, unwrapResponse
  } from "./api.service";
import
  {
    Observable, catchError
  } from "rxjs";
import { HttpParams } from "@angular/common/http";
import {
 Document, DocumentRow, PartialData
} from "../models";

@Injectable({
  providedIn : "root"
})
export class DocumentService
{
  private static readonly url = "Document";

  private handleError: ErrorHandlerCreator;

  constructor(private readonly api: APIService,
    private readonly responseErrorHandler: ResponseErrorHandler
  )
  {
    this.handleError = this.responseErrorHandler.createErrorHandler("DocumentService");
  }

  getDocumentById(id: string): Observable<Blob | null>
  {
    let params = new HttpParams();

    params = params.set("documentId", id);

    const options = {
      params : params, responseType : "blob", asset : true
    };

    return this.api.get<Blob | null>(DocumentService.url, options).pipe(
      unwrapResponse(),
      catchError(this.handleError("getDocumentById", null))
    );
  }

  getDocumentsByPersonId(personId: string, skip: number, take: number):
    Observable<PartialData<DocumentRow> | null>
  {
    const options = { params: new HttpParams().set("personId", personId).set("skip", skip).set("take", take) };

    return this.api.get<PartialData<DocumentRow>>(`${DocumentService.url}/GetDocumentsByPersonId`, options).pipe(
      unwrapResponse(),
      catchError(this.handleError("getDocumentsByPersonId", null))
    );
  }

  uploadDocument(studentId: string, newDocuments?: Document[]): Observable<boolean | null>
  {
    const fd: FormData = new FormData();

    let i = 0;

    newDocuments?.forEach(document =>
    {
      if (document.File != null)
      {
        fd.append("documents[" + i + "].Description", document.Description? document.Description:"");
        fd.append("documents[" + i + "].Type", document.Type);
        fd.append("documents[" + i + "].File", document.File, document.File.name);
      }
      i++;
    });

    return this.api.post<boolean | null>(`${DocumentService.url}/Upload`, fd, { params: { studentId }}).pipe(
      unwrapResponse(),
      catchError(this.handleError("Upload", null))
    );
  }
}